import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Colors from "~/constants/colors";
import { Menus } from "~/constants/menus";
import { Template, TemplateApi, TemplateStatus } from "~/services/template";
import Trans from "~/types/translationKeys";
import { getQueryParams } from "~/utils/common";
import Button from "../Button";
import { Container } from "../Container";
import Icon from "../Icon";
import styles from "./Header.module.scss";
import HeaderDrawer from "./components/HeaderDrawer";

const Header: FC = () => {
  const router = useRouter();
  const { utm_campaign, utm_source } = router.query;
  const [isActive, setIsActive] = useState(false);
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);
  const isBlog = router.asPath.includes("resources/b");
  // const [isShowDiscordBanner, setIsShowDiscordBanner] = useState(false);
  const isAIStudio = router.asPath.includes("ai_studio");
  const [isScrolled, setIsScrolled] = useState(false);
  const { t } = useTranslation();
  const isDarkMode = isAIStudio && !isScrolled;
  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    TemplateApi.getList({
      page: 1,
      limit: 10,
      status: TemplateStatus.PUBLISHED,
    }).then((res) => {
      setTemplates(res);
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (typeof window !== "undefined") {
        setIsScrolled(window.scrollY > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const isShowBanner = localStorage.getItem("show_discord");
  //     setIsShowDiscordBanner(!isShowBanner);
  //   }
  // }, []);

  return (
    <div className={styles.header_container}>
      <div className={styles.discord_deal}>
        <Container className="flex flex-col items-center lg:gap-2 lg:flex-row">
          <Image
            src={"/svgs/discord_small.svg"}
            alt="Discord"
            width={180}
            height={180}
            className="absolute bottom-0 left-0 top-0 object-fill h-full !hidden sm:!block -z-[1]"
          />
          <Image
            src={"/svgs/discord_mobile_small.svg"}
            alt="Discord"
            width={160}
            height={160}
            className="absolute bottom-0 left-0 !block sm:!hidden -z-[1]"
          />
          <p className="text-xs font-semibold sm:text-sm">
            💻 Chat with Our Developers
          </p>
          <div className="hidden lg:block">•</div>
          <div className="inline-block text-center">
            <Image
              src="/svgs/discord.svg"
              alt="Discord"
              width={24}
              height={24}
              className="inline w-5 h-5 sm:w-6 sm:h-6"
            />
            <div className="inline text-xs sm:text-sm">
              {" "}
              <a
                href="https://discord.gg/bTs9AvZrFv"
                target="_blank"
                className="font-semibold"
              >
                Join our Discord
              </a>{" "}
              to talk directly with the devs. Share your feedback and ideas!
            </div>
          </div>
        </Container>
      </div>
      <header
        className={classNames(styles.header, {
          [styles.dark_mode]: isDarkMode,
        })}
      >
        <Container className="flex items-center justify-between relative z-[15] gap-4 md:gap-10">
          <div className="flex items-center gap-3">
            <div
              className={classNames(styles.hamburger, {
                [styles.active]: isActive,
              })}
              onClick={() => {
                setIsActive(!isActive);
              }}
            >
              <div className={styles.line} />
              <div className={styles.line} />
              <div className={styles.line} />
            </div>
            <Link href={`/#`} className={styles.header_logo}>
              <Image
                src={isDarkMode ? "/svgs/logo_white.svg" : "/svgs/logo.svg"}
                alt="CX Genie logo"
                fill
              />
            </Link>
          </div>

          <nav className={classNames(styles.nav, "hidden lg:flex")}>
            <ul className={styles.menu_list}>
              {Menus.map((menu) => (
                <li className={styles.menu_item} key={menu.key}>
                  {menu.submenu ? (
                    <div
                      className={classNames(
                        styles.menu_item_target,
                        "text-base cursor-pointer flex items-center gap-1 -mr-3"
                      )}
                    >
                      {t(menu.text)}
                      <Icon name="arrow-square-down-2" />
                      <div className={styles.submenu}>
                        {menu.submenu.map((submenu, index) => (
                          <Fragment key={index}>
                            <Link href={submenu.link} target="_blank">
                              <div className={styles.submenu_item}>
                                {t(submenu.label)}
                              </div>
                            </Link>
                            {index < menu.submenu.length - 1 && (
                              <div className={styles.menu_divider} />
                            )}
                          </Fragment>
                        ))}
                        {menu.key === "tools"
                          ? templates.map((template, index) => (
                              <Fragment key={template.id}>
                                <Link
                                  href={`https://cxgenie.ai/templates/${template.id}`}
                                  target="_blank"
                                >
                                  <div className={styles.submenu_item}>
                                    {`${template.name}`}
                                  </div>
                                </Link>
                                {index < templates.length - 1 && (
                                  <div className={styles.menu_divider} />
                                )}
                              </Fragment>
                            ))
                          : null}
                      </div>
                    </div>
                  ) : (
                    <Link
                      href={menu.link}
                      aria-label={menu.text}
                      target={
                        menu.link?.startsWith("https") ? "_blank" : undefined
                      }
                    >
                      {t(menu.text)}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>

          <div className="items-center hidden gap-3 md:flex">
            {/* <DropdownLanguage isDarkMode={isDarkMode} /> */}
            <Link
              type="outline"
              href={{
                pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                query: {
                  utm_source: isBlog
                    ? `${cookie["utm_source"] || "blog"}`
                    : `${cookie["utm_source"] || utm_source || ""}`,
                  utm_campaign,
                  utm_blog: isBlog ? cookie["utm_blog"] : "",
                  others: cookie["others"] || getQueryParams(router.asPath),
                },
              }}
              target="_blank"
            >
              <Button variant="primary" type="borderless" size="large">
                {t(Trans.sign_in)}
              </Button>
            </Link>
            {/* <Button
            size="large"
            className="hidden px-3 md:inline-block"
            type="outline"
            variant="secondary"
            onClick={() => {
              (window as any)?.demo_conversion?.();
              window.open(
                process.env.NEXT_PUBLIC_DEMO_URL,
                "_blank",
                "noopener,noreferrer"
              );
            }}
          >
            {t(Trans.see_demo)}
          </Button> */}
            <Link
              type="outline"
              href={{
                pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                query: {
                  utm_source: isBlog
                    ? `${cookie["utm_source"] || "blog"}`
                    : `${cookie["utm_source"] || utm_source || ""}`,
                  utm_campaign,
                  utm_blog: isBlog ? cookie["utm_blog"] : "",
                  others: cookie["others"] || getQueryParams(router.asPath),
                },
              }}
              target="_blank"
            >
              <Button size="large" className="hidden px-3 md:inline-block">
                {t(Trans.try_now_it_is_free)}
              </Button>
            </Link>
          </div>
          <div className="flex gap-2 md:hidden">
            {/* <DropdownLanguage isMobile isDarkMode={isDarkMode} /> */}

            <Link
              href={{
                pathname: process.env.NEXT_PUBLIC_DASHBOARD_URL,
                query: {
                  utm_source: isBlog
                    ? `${cookie["utm_source"] || "blog"}`
                    : `${cookie["utm_source"] || utm_source || ""}`,
                  utm_campaign,
                  utm_blog: isBlog ? cookie["utm_blog"] : "",
                  others: cookie["others"] || getQueryParams(router.asPath),
                },
              }}
              target="_blank"
            >
              <Button type="outline">{t(Trans.sign_in)}</Button>
            </Link>
          </div>
        </Container>
        <HeaderDrawer
          open={isActive}
          handleClose={() => setIsActive(false)}
          templates={templates}
        />
      </header>
    </div>
  );
};

export default Header;
